import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';

const TermsOfUse = () => {

    return (
        <>
            <SEO title="شروط الاستخدام" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                    title="شروط الاستخدام"
                    page="شروط الاستخدام"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <h4>مقدمة</h4>
                                    <p>مرحبًا بكم في موقع كاتبي للحلول الرقمية. باستخدام هذا الموقع، فإنك توافق على الالتزام بالشروط والأحكام التالية. يرجى قراءتها بعناية قبل استخدام الموقع.</p>
                                    
                                    <h4>استخدام الموقع</h4>
                                    <p>تُستخدم محتويات هذا الموقع لأغراض المعلومات العامة فقط وقد تخضع للتغيير دون إشعار. لا نقدم أي ضمان بشأن دقة أو اكتمال المعلومات المقدمة. استخدامك لأي معلومة أو مادة على هذا الموقع هو على مسؤوليتك الخاصة، ولن نكون مسؤولين عن ذلك.</p>
                                    
                                    <h4>حقوق الملكية الفكرية</h4>
                                    <p>جميع العلامات التجارية والمواد الموجودة على هذا الموقع هي ملك لشركة كاتبي للحلول الرقمية أو للمرخصين لها. لا يجوز لك إعادة إنتاج أو توزيع أو استخدام أي من هذه المواد دون إذن كتابي صريح منا.</p>
                                    
                                    <h4>روابط لمواقع أخرى</h4>
                                    <p>قد يتضمن موقعنا روابط إلى مواقع أخرى. هذه الروابط هي لتوفير مزيد من المعلومات، ولا تعني أننا نصادق على محتويات تلك المواقع. لا نتحمل أي مسؤولية عن محتوى المواقع المرتبطة.</p>
                                    
                                    <h4>الخصوصية</h4>
                                    <p>نحن ملتزمون بحماية خصوصيتك. سيتم استخدام أي معلومات تقدمها لنا فقط وفقًا لسياسة الخصوصية الخاصة بنا.</p>
                                    
                                    <h4>التغييرات على الشروط</h4>
                                    <p>نحتفظ بالحق في تعديل هذه الشروط في أي وقت. يجب عليك مراجعة هذه الصفحة بانتظام للتأكد من رضاك عن أي تغييرات.</p>
                                    
                                    <h4>القانون المعمول به</h4>
                                    <p>تخضع هذه الشروط لقوانين الدولة المعمول بها، وأي نزاعات قد تنشأ عنها تكون ضمن اختصاص المحاكم القضائية في الدولة.</p>

                                    <h4>الاتصال بنا</h4>
                                    <p>إذا كان لديك أي أسئلة أو استفسارات حول هذه الشروط، يرجى الاتصال بنا على <a href="mailto:support@katbi.net">support@katbi.net</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default TermsOfUse;
