import React from 'react';
import { Helmet } from 'react-helmet';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import CaseStudyProp from '../component/casestudy/CaseStudyProp';

const CaseStudy = () => {
    return (
        <>
            <Helmet>
                <title>مشاريعي - كاتبي للحلول الرقمية</title>
                <meta name="description" content="أحدث المشاريع التي قمت بإنشاءها." />
                <link rel="canonical" href="https://www.katbi.net/case-study" />
            </Helmet>
            <SEO title="مشاريعي" description="أحدث المشاريع التي قمت بإنشاءها" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                    title="مشاريعي"
                    paragraph="نقوم بتصميم وتطوير تطبيقات الويب والهواتف المحمولة لعملائنا في جميع أنحاء العالم"
                    styleClass="thumbnail-3"
                    mainThumb="/images/banner/banner-thumb-5.png"
                />
                <div className="pt--250 pt_lg--200 pt_md--100 pt_sm--80 case-study-page-area">
                    <div className="container">
                        <CaseStudyProp />
                    </div>
                </div>
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default CaseStudy;
