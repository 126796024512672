import React from 'react';
import { Link } from 'react-router-dom';
import WidgetPost from './widget/WidgetPost';
import WidgetCategory from './widget/WidgetCategory';
import { FaSearch, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaPinterest, FaYoutube} from "react-icons/fa";

const BlogSidebar = () => {
    return (
        <div className="axil-sidebar">
            <div className="widget widget-search">
                <h4 className="widget-title">بحث</h4>
                <form action="#" className="blog-search">
                    <input type="text" placeholder="بحث..." />
                    <button className="search-button"><FaSearch /></button>
                </form>
            </div>
            <div className="widget widget-categories">
                <h4 className="widget-title">التصنيفات</h4>
                <WidgetCategory />
            </div>
            <div className="widget widge-social-share">
                <div className="blog-share">
                    <h5 className="title">تابعني:</h5>
                    <ul className="social-list list-unstyled">
                    <a href="https://facebook.com/bkatbi" aria-label="Facebook">فيسبوك</a>
                    <a href="https://twitter.com/katbi89" aria-label="Twitter">إكس</a>
                    <a href="https://www.linkedin.com/in/katbi89" aria-label="LinkedIn">لينكدان</a>
                    <a href="https://www.instagram.com/katbidotnet" aria-label="Instagram">انستغرام</a>
                    <a href="https://www.tiktok.com/en/katbidotnet" aria-label="TikTok">تيك توك</a>
                    </ul>
                </div>
            </div>
            <div className="widget widget-recent-post">
                <h4 className="widget-title">أحدث المقالات</h4>
                <WidgetPost />
            </div>
            <div className="widget widget-banner-ad">
                <Link to="/contact">
                    <img src={process.env.PUBLIC_URL + "/images/banner/widget-banner.png"} alt="banner" />
                </Link>
            </div>
        </div>
    )
}

export default BlogSidebar;