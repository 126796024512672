import React from 'react';
import { Helmet } from 'react-helmet';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import AboutOne from '../component/about/AboutOne';
import BannerOne from '../component/banner/BannerOne';
import BlogOne from '../component/blog/BlogOne';
import BrandOne from '../component/brand/BrandOne';
import CounterUpOne from '../component/counterup/CounterUpOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import PricingOne from '../component/pricing/PricingOne';
import ProjectOne from '../component/project/ProjectOne';
import ServicePropOne from '../component/service/ServicePropOne';
import TestimonialOne from '../component/testimonial/TestimonialOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';

const DigitalAgency = () => {
    return (
        <>
            <Helmet>
                <title>وكالة رقمية - كاتبي للحلول الرقمية</title>
                <meta name="description" content="نحن نقدم خدمات رقمية متخصصة لمساعدتك في تحقيق أهدافك الرقمية." />
                <link rel="canonical" href="https://www.katbi.net/digital-agency" />
            </Helmet>
            <SEO title="وكالة رقمية" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerOne />
                <div className="section section-padding-2 bg-color-dark">
                    <div className="container">
                        <SectionTitle 
                            subtitle="ماذا يمكننا أن نفعل من أجلك"
                            title="الخدمات التي يمكننا مساعدتك بها"
                            description="نحن نقدم مجموعة متنوعة من الخدمات الرقمية لتلبية احتياجاتك."
                            textAlignment="heading-light-left"
                            textColor=""
                        />
                        <div className='row'>
                            <ServicePropOne colSize="col-xl-4 col-md-6" serviceStyle="" itemShow="6" />
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-10">
                        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li>
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Circle" /></li>
                        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"} alt="Circle" /></li>
                    </ul>
                </div>
                <AboutOne />
                <ProjectOne />
                <CounterUpOne />
                <TestimonialOne />
                <div className="section bg-color-light section-padding">
                    <div className="container">
                        <SectionTitle 
                            subtitle="خطط الأسعار"
                            title="لقد بنينا حلولا لكل من .."
                            description="أسعار مرنة للأفراد والشركات"
                            textAlignment=""
                            textColor=""
                        />
                        <PricingOne />
                    </div>
                    <ul className="list-unstyled shape-group-3">
                        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-1.png"} alt="shape" /></li>
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"} alt="shape" /></li>
                    </ul>
                </div>
                <BrandOne />
                <BlogOne />
                <CtaLayoutOne /> 
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default DigitalAgency;
