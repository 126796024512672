import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SectionTitle from '../elements/section-title/SectionTitle';
import TestimonialPropTwo from '../component/testimonial/TestimonialPropTwo';
import TestimonialData from "../data/testimonial/TestimonialData.json";
import { slugifyArabic } from '../utils';

const allData = TestimonialData;

const Testimonials = () => {

    const facebookData = allData.filter(data => slugifyArabic(data.fromtext) === "facebook");
    const googleData = allData.filter(data => slugifyArabic(data.fromtext) === "google");
    const yelpData = allData.filter(data => slugifyArabic(data.fromtext) === "yelp");

    return (
        <>
        <SEO title="آراء العملاء" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="آراء العملاء"
                page="المراجعات"
                />
                
                <div className="section section-padding customer-review-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <SectionTitle 
                                    subtitle=""
                                    title="مراجعات جوجل"
                                    description="نحن نفتخر برضى عملائنا. فيما يلي بعض المراجعات من عملائنا على جوجل."
                                    textAlignment="heading-left"
                                    textColor=""
                                />
                            </div>
                            <div className="col-lg-4">
                                <div className="review-site-logo">
                                    <a href="https://www.google.com/"><img src={process.env.PUBLIC_URL + "/images/icon/google.png"} alt="Google" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <TestimonialPropTwo colSize="col-lg-4" itemShow="3" testimonialData={googleData} />
                        </div>
                    </div>
                </div>

                <div className="section section-padding customer-review-area bg-color-dark overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <SectionTitle 
                                    subtitle=""
                                    title="مراجعات فيسبوك"
                                    description="تعرف على ما يقوله عملاؤنا عنا على فيسبوك."
                                    textAlignment="heading-light-left"
                                    textColor=""
                                />
                            </div>
                            <div className="col-lg-4">
                                <div className="review-site-logo">
                                    <a href="https://www.facebook.com/"><img src={process.env.PUBLIC_URL + "/images/icon/fb.png"} alt="Facebook" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <TestimonialPropTwo colSize="col-lg-4" itemShow="3" layoutStyle="testimonial-light" testimonialData={facebookData} />
                        </div>
                    </div>
                    <ul className="shape-group-11 list-unstyled">
                        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-6.png"} alt="line" /></li>
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="line" /></li>
                    </ul>
                </div>

                <div className="section section-padding customer-review-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <SectionTitle 
                                    subtitle=""
                                    title="مراجعات يلب"
                                    description="اكتشف ما يقوله عملاؤنا على يلب."
                                    textAlignment="heading-left"
                                    textColor=""
                                />
                            </div>
                            <div className="col-lg-4">
                                <div className="review-site-logo">
                                    <a href="https://www.yelp.com/"><img src={process.env.PUBLIC_URL + "/images/icon/yelp.png"} alt="Yelp" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <TestimonialPropTwo colSize="col-lg-4" itemShow="3" testimonialData={yelpData} />
                        </div>
                    </div>
                </div>

                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default Testimonials;
