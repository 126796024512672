import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BlogGridTwo from '../component/blog/BlogGridTwo';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BlogData from "../data/blog/BlogData.json";
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import { slugifyArabic } from '../utils';

const allBlogData = BlogData;

const BlogCategory = () => {
    const params = useParams();
    const blogSlug = params.slug;

    const getBlogData = allBlogData.filter(blog => slugifyArabic(blog.category) === blogSlug);
    const categoryTitle = getBlogData.length > 0 ? getBlogData[0].category : "التصنيفات";

    return (
        <>
            <Helmet>
                <title>{`تصنيف ${categoryTitle} - كاتبي للحلول الرقمية`}</title>
                <meta name="description" content={`استعرض مقالات تصنيف ${categoryTitle} على كاتبي للحلول الرقمية.`} />
                <link rel="canonical" href={`https://www.katbi.net/blog-category/${blogSlug}`} />
            </Helmet>
            <SEO title={`تصنيف ${categoryTitle}`} />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                    title={categoryTitle}
                    page="Blog"
                />
                <div className="section-padding-equal">
                    <div className="container">
                        <div className="row">
                            <BlogGridTwo data={getBlogData} colSize="col-lg-4"/>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default BlogCategory;
