import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BannerFour from '../component/banner/BannerFour';
import BlogOne from '../component/blog/BlogOne';
import BrandOne from '../component/brand/BrandOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProjectFour from '../component/project/ProjectFour';
import TestimonialOne from '../component/testimonial/TestimonialOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import { Helmet } from 'react-helmet';
import WhatsAppButton from './WhatsAppButton'; // Import the WhatsAppButton component

const HomeStartup = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
                <title>كاتبي للحلول الرقمية</title>
                <meta name="description" content="اكتشف حلولنا الرقمية المتكاملة والمبتكرة لتحسين أدائك التجاري وزيادة الكفاءة في عالم التكنولوجيا." />
                <meta name="keywords" content="كاتبي, katbi, حلول رقمية, تكنولوجيا, تطوير الأعمال, التجارة الإلكترونية, تحسين الأعمال, برمجة, تطبيقات موبايل" />
            </Helmet>
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerFour />
                <ProjectFour />
                <TestimonialOne />
                <BrandOne />
                <BlogOne />
                <CtaLayoutOne />
                <FooterOne />
                <WhatsAppButton /> {/* Include the WhatsAppButton component */}
            </main>
        </>
    );
};

export default HomeStartup;
