import React from 'react';
import { Helmet } from 'react-helmet';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessOne from '../component/process/ProcessOne';
import AboutThree from '../component/about/AboutThree';
import AboutFour from '../component/about/AboutFour';
import AboutFive from '../component/about/AboutFive';

const AboutUs = () => {

    return (
        <>
            <Helmet>
                <title>من نحن؟ - كاتبي للحلول الرقمية</title>
                <meta name="description" content="نحن نصمم ونطور تطبيقات الويب والهواتف المحمولة لعملائنا في جميع أنحاء العالم. اكتشف المزيد عن فريقنا وخدماتنا." />
                <link rel="canonical" href="https://www.katbi.net/about" />
            </Helmet>
            <SEO title="من نحن؟" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                    title="واحدة من أسرع الوكالات نمواً"
                    paragraph="نحن نصمم ونطور تطبيقات الويب والهواتف المحمولة لعملائنا في جميع أنحاء العالم."
                    styleClass="thumbnail-4"
                    mainThumb="/images/banner/banner-thumb-3.png"
                />
                <AboutFour />
                <AboutThree />
                <AboutFive />
                <ProcessOne />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;
