import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe } from 'react-icons/fa';


const AboutTwo = () => {
    return (
        <div className="section-padding">
            <div className="container">
            <div className="row">
                    <div className="col-lg-6">
                        <div className="why-choose-us">
                            <div className="section-heading heading-left">
                                <span className="subtitle">من نحن؟</span>
                                <h3 className="title">لماذا العلامة التجارية مهمة؟</h3>
                                <p>العلامة التجارية هي تمثيل فريد وتعريف مميز لشركة أو منتج في عقول العملاء. وتعد العلامة التجارية أمرًا هامًا للعديد من الأسباب</p>
                            </div>
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><FaCompress /> التميز والتفرد</Accordion.Header>
                                    <Accordion.Body>
                                    تساعد العلامة التجارية على تحديد شركتك أو منتجك بشكل فريد من بين المنافسين. عندما يتعرف العملاء على العلامة التجارية الخاصة بك ويرتبطون بها، يكونون أكثر عرضة لاختيار منتجاتك أو خدماتك على حساب المنافسة.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><FaCode /> بناء الثقة والولاء</Accordion.Header>
                                    <Accordion.Body>
                                    تساعد العلامة التجارية على بناء الثقة بينك وبين عملائك. عندما يكون لديك علامة تجارية قوية وإيجابية، يصبح العملاء أكثر استعدادًا للتعامل معك والبقاء معك على المدى الطويل.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><FaGlobe /> التسويق الفعّال</Accordion.Header>
                                    <Accordion.Body>
                                    تسهل العلامة التجارية عمليات التسويق. عندما يكون لديك هوية تجارية واضحة، يمكنك توجيه استراتيجيات التسويق بشكل أفضل، سواء كان ذلك عبر وسائل الإعلام الاجتماعية، الإعلانات، أو حملات الترويج.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box shadow-box mb--30">
                            <h3 className="title">احصل على عرض سعر مجاني الآن</h3>
                            <FormOne />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;