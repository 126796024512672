function flatDeep(arr, d = 1) {
  return d > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), [])
               : arr.slice();
}

// const slugify = function(text) {
//     return text
//       .toString()
//       .toLowerCase()
//       .replace(/\s+/g, '-') // Replace spaces with -
//       .replace(/[^\w-]+/g, '') // Remove all non-word chars
//       .replace(/--+/g, '-') // Replace multiple - with single -
//       .replace(/^-+/, '') // Trim - from start of text
//       .replace(/-+$/, '') // Trim - from end of text
// }

function slugifyArabic(text) {
  if (text === undefined || text === null) {
    return ''; // or handle the undefined/null case as needed
  }

  return text
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-') // You can include your specific slugification logic here
    .replace(/[^\w\u0600-\u06FF-]+/g, ''); // Additional clean-up (remove non-word characters except Arabic letters and '-')
}

function containsObject(obj, list) {
  var i;
  for (i = 0; i < list.length; i++) {
  console.log()
      if (list[i].slug === obj.slug) {
          return i;
      }
  }

  return -1;
}


export {flatDeep, slugifyArabic, containsObject};