import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';

const PrivacyPolicy = () => {
    return (
        <>
            <SEO title="سياسة الخصوصية" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                    title="سياسة الخصوصية"
                    page="سياسة الخصوصية"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <div className="section-title">
                                        <h5 className="title">تم نشر هذه السياسة في 10 سبتمبر 2021.</h5>
                                    </div>
                                    <h4>الامتثال للائحة حماية البيانات العامة (GDPR)</h4>
                                    <p>نص عن الامتثال للائحة حماية البيانات العامة.</p>
                                    <h4>حول كاتبي</h4>
                                    <p>نص عن كاتبي، يمكنك مراسلتنا عبر البريد الإلكتروني <a href="mailto:example@abstrak.com">example@abstrak.com</a></p>
                                    <h4>متى نجمع بياناتك الشخصية</h4>
                                    <ul>
                                        <li>عند استخدام خدماتنا أو شراء منتجاتنا.</li>
                                        <li>عند التواصل معنا للحصول على دعم أو استفسارات.</li>
                                        <li>عند الاشتراك في نشراتنا البريدية.</li>
                                        <li>عند تقديم تعليقات أو مشاركات عبر مواقع التواصل الاجتماعي.</li>
                                    </ul>
                                    <h4>لماذا نجمع ونستخدم البيانات الشخصية</h4>
                                    <p className="mb--20">نص عن لماذا نجمع البيانات الشخصية.</p>
                                    <ul>
                                        <li>لتوفير وتحسين خدماتنا.</li>
                                        <li>لتلبية طلبات العملاء وتقديم الدعم الفني.</li>
                                        <li>لتسويق خدماتنا ومنتجاتنا.</li>
                                        <li>لتحليل وتطوير خدماتنا.</li>
                                        <li>للامتثال للقوانين واللوائح.</li>
                                    </ul>
                                    <h4>نوع البيانات الشخصية التي نجمعها</h4>
                                    <p>نص عن نوع البيانات الشخصية التي نجمعها.</p>
                                    <h4>المعلومات التي نجمعها تلقائيًا</h4>
                                    <p>نص عن المعلومات التي نجمعها تلقائيًا.</p>
                                    <h4>استخدام الكوكيز والمنارات الشبكية</h4>
                                    <p className="mb--20">نص عن الكوكيز والمنارات الشبكية.</p>
                                    <p className="mb--20">نص عن الكوكيز والمنارات الشبكية.</p>
                                    <p>نص عن الكوكيز والمنارات الشبكية.</p>
                                    <h4>مدة الاحتفاظ ببياناتك</h4>
                                    <p className="mb--20">نص عن مدة الاحتفاظ بالبيانات.</p>
                                    <p>نص عن مدة الاحتفاظ بالبيانات.</p>
                                    <h4>حقوقك في بياناتك الشخصية</h4>
                                    <p>نص عن حقوقك في بياناتك الشخصية.</p>
                                    <h4>سياسة الخصوصية الخاصة بـ Hotjar</h4>
                                    <p>نص عن سياسة الخصوصية الخاصة بـ Hotjar.</p>
                                    <h4>التغييرات على سياسة الخصوصية هذه</h4>
                                    <p>نص عن التغييرات على سياسة الخصوصية.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PrivacyPolicy;
