import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Tilty from 'react-tilty';


const BannerFour = () => {
    return (
        <div className="banner banner-style-4">
            <div className="container">
                <div className="banner-content">
                    <h1 className="title">كاتبي للحلول الرقمية</h1>
                    <p>جميع حلولك الرقمية في مكان واحد</p>
                    <div>
                            <Link to={process.env.PUBLIC_URL + "/services"} className="axil-btn btn-fill-primary btn-large">عرض الخدمات</Link>
                           {/* <a href="https://vcard.katbi.net/login" className="axil-btn btn-fill-primary btn-large" target="_blank" rel="noopener noreferrer">
    أنشئ موقعك مجاناً
  </a> */}

                    </div>
                </div>
                <div className="banner-thumbnail">
                    <div className="large-thumb">
                        <Tilty perspective={3000}>
                            <img src={process.env.PUBLIC_URL + "/images/banner/banner-thumb-7.png"} alt="Shape" />
                        </Tilty>
                    </div>
                </div>
                <div className="banner-social">
                <   div className="border-line" />
                <ul className="list-unstyled social-icon">
            <li>
                <a href="https://facebook.com/bkatbi" aria-label="Facebook">
                    فيسبوك <FaFacebookF />
                </a>
            </li>
            <li>
                <a href="https://twitter.com/katbi89" aria-label="Twitter">
                    تويتر <FaTwitter />
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/katbidotnet" aria-label="Instagram">
                    انستغرام <FaInstagram />
                </a>
            </li>
        </ul>
                </div>
            </div>
            <ul className="list-unstyled shape-group-19">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-29.png"} alt="Bubble" />
                </li>
                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/line-7.png"} alt="Bubble" />
                </li>
            </ul>
        </div>
    )
}

export default BannerFour;
