import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProjectFive from '../component/project/ProjectFive';

const ProjectGridFour = () => {
    return (
        <>
            <SEO title="مشاريع بعرض كامل بثلاثة أعمدة" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                    title="أحدث المشاريع"
                    paragraph="نظرة سريعة على آخر المشاريع التي قمت بإنشائها"
                    styleClass=""
                    mainThumb="/images/banner/banner-thumb-1.png"
                />
                <ProjectFive colSize="row-cols-sm-2 row-cols-lg-3" parentClass="project-column-3" perPageShow="9" />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    );
}

export default ProjectGridFour;
