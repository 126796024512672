import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServicePropOne from '../component/service/ServicePropOne';
import AboutThree from '../component/about/AboutThree';
import CounterUpTwo from '../component/counterup/CounterUpTwo';

const ServiceTwo = () => {
    return (
        <>
            <SEO title="خدمة اثنان" />

            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                    title="أفضل الحلول لأعمالك التجارية"
                    paragraph="امنح عملك شعارًا فريدًا ليتميز عن الآخرين. سنقوم بإنشاءه لشركتك."
                    styleClass=""
                    mainThumb="/images/banner/banner-thumb-4.png"
                />
                <CounterUpTwo />
                <div className="section section-padding bg-color-light">
                    <div className="container">
                        <SectionTitle 
                            subtitle="ماذا يمكننا أن نفعل من أجلك"
                            title="الخدمات التي يمكننا <br> مساعدتك بها"
                            description=""
                            textAlignment="heading-left"
                            textColor=""
                        />
                        <div className="row">
                            <ServicePropOne colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" itemShow="6" marginTop="no" />
                        </div>
                    </div>
                </div>

                <AboutThree />
                
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default ServiceTwo;
